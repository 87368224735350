import React from "react";
import Layout from "../../../components/layout"
import JobsTable from "../../../components/JobsTable";
import HeaderENIT from "../../../components/HeaderENIT";
import FooterENIT from "../../../components/FooterENIT";

const columns = [
    {id: 'job', label: 'Positions', minWidth: 170, link: "link"},
];

function createData(job, link) {
    return {job, link};
}


const rows = [
        createData('Computer Engineer', 'https://gnb1.hiringplatform.ca/24723-computer-engineer/77221-registration/en'),
        createData('Software Engineer and Designer', 'https://gnb1.hiringplatform.ca/24727-software-engineer-and-designer/77226-registration/en'),
        createData('Information Systems Analyst and Consultant', 'https://gnb1.hiringplatform.ca/24611-information-systems-analysts-and-consultants/77095-registration/en'),
        createData('Database Analyst and Data Administrator', 'https://gnb1.hiringplatform.ca/24731-database-analyst-and-data-administrator/77231-registration/en'),
        createData('Computer Programmer and Interactive Media Developer', 'https://gnb1.hiringplatform.ca/24738-computer-programmer-and-interactive-media-developer/77237-registration/en'),
        createData('Web Designer and Developer', 'https://gnb1.hiringplatform.ca/24739-web-designer-and-developer/77240-registration/en'),
        createData('Computer Network Technician', 'https://gnb1.hiringplatform.ca/24747-computer-network-technician/77248-registration/en'),
        createData('User Support Technician', 'https://gnb1.hiringplatform.ca/24751-user-support-technician/77252-registration/en'),
        createData('Information System Testing Technician', 'https://gnb1.hiringplatform.ca/24761-information-system-testing-technician/77258-registration/en'),
        createData('Sales / Marketing / Business Development', 'https://gnb1.hiringplatform.ca/24764-sales-marketing-business-development/77261-registration/en'),
        createData('Account Manager', 'https://gnb1.hiringplatform.ca/24766-account-manager/77265-registration/en'),
        createData('Project Manager', 'https://gnb1.hiringplatform.ca/24772-project-manager/77270-registration/en'),
    ]
;

const it = () => (
    <Layout>
        <HeaderENIT/>
        <div className={"container-xl tac-lg"}>
            <h2 className={'tac'}>New Brunswick IT Industry</h2>
            <h2 className={'tac pb5'}>Virtual Job Connector</h2>
            <p><b>New Brunswick IT employers are looking for hundreds of professionals with experience in a variety of
                occupations!</b></p>
            <p><b>Representatives such as CompuTech, IGT, Appcast, Innovatia, Introhive, Moneris, Parados, PQA Testing, Smart Skin Technologies, TrojAI, Spandrel Interactive and VidCruiter are seeking talent and will be holding discovery interviews with potential
                candidates.</b></p>
            <div className={"container-md py4"}>
                <p>Transfer your skills to Canada's east coast! In New Brunswick, you’ll find:</p>

                <ul className={"pl4 pl0-md tal"}>
                    <li>Short commutes</li>
                    <li>Affordable housing costs</li>
                    <li>English and French school systems</li>
                    <li>Accessible post-secondary education</li>
                    <li>Career opportunities</li>
                </ul>
            </div>

            <p>For more information on supports for employment, please visit the Government of New Brunswick’s site Working
                <a target={"_blank"} href={"https://workingnb.ca/"}> NB | WorkingNB.</a></p>


            <p><u>More companies are signing up daily!</u></p>


            <div className={"c jc ac"}>
                <h2 className={"py4"}> All Provincial Jobs</h2>
            </div>
        </div>
        <div className={"container-md c ac jc"}>
            <JobsTable rows={rows} columns={columns}/>
        </div>
        <FooterENIT/>
    </Layout>
);

export default it;