import React from "react";
import PQALogo from "../images/PQA-logo.png"
import bulletProofLogo from "../images/bulletproof-logo.png"
import innovatiaLogo from "../images/innovatia-logo.png"
import JDILogo from "../images/JDI-logo.svg"
import SNBLogo from "../images/Service-NB.svg"
import fiddleLogo from "../images/Fiddlehead_logo.png"
import vidCruiterLogo from "../images/vidcruiter_logo.svg"
import {Link} from "gatsby"

export default function FooterENIT() {
    return (<>
            <div className={"container-xl"}>
                <div className={"r jc ac mt0 fww"}>
                    <a target="_blank" href="https://www.pqatesting.com"><img className={"mx4 p3"} width="220" src={PQALogo} alt="PQA Testing Logo"/></a>
                    <a target="_blank" href="https://www.bulletproofsi.com"><img className={"mx4 p3"} width="220" src={bulletProofLogo} alt="Bulletproof Logo"/></a>
                    <a target="_blank" href="https://www.innovatia.net"><img className={"mx4 p3"} width="220" src={innovatiaLogo} alt="Innovatia Logo"/></a>
                    <a target="_blank" href="https://jdirving.com"><img className={"mx4 p3"} width="220" src={JDILogo} alt="JDI Logo"/></a>
                    <a target="_blank" href="https://www2.snb.ca/content/snb/en.html"><img className={"mx4 p3"} width="220" src={SNBLogo} alt="Service NB Logo"/></a>
                    <a target="_blank" href="https://fiddlehead.io"><img className={"mx4 p3"} width="220" src={fiddleLogo} alt="Fiddlehead NB Logo"/></a>
                    <a target="_blank" href="https://vidcruiter.com"><img className={"mx4 p3"} width="220" src={vidCruiterLogo} alt="VidCruiter Logo"/></a>
                </div>
            </div>
            <div className={"container-xxl footer mb6"}>
                <hr/>
                <div className={"r-c-lg jsb ac"}>
                    <div className={"r"}>
                        <p className={"mb0 tac-sm"}>All content © Government of New Brunswick. All rights reserved.</p>
                    </div>
                    <div className={"r-c-sm mt4-lg"}>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en.html?_ga=2.172893923.1322334766.1613581688-1203811668.1613581688">
                            Feedback
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en/corporate/connect.html">
                            Social Media
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en/admin/privacy.html">
                            Privacy
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en/admin/disclaimer.html">
                            Disclaimer
                        </Link>
                    </div>
                </div>
                {/*<div className={"r jc-lg"}>*/}
                {/*    <p className={"py3 "}><Link className={"c-green"} target={"_blank"} to={"https://vidcruiter.com/"}>Powered*/}
                {/*        by VidCruiter</Link></p>*/}
                {/*</div>*/}
            </div>
        </>
    );
}